import React, { useState } from "react";
import { Select, Input } from "antd";
import {
  CheckCircleFilled,
  SearchOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import styles from "./index.module.less";
import { CompetitionPlatFormsIconsMap } from "@/lib/hooks/utils/plateforms";
import { postSubmitPlatformApi } from "@/lib/service";
import { useTranslation } from "next-i18next";

export function CustomSelect({
  onSelect,
  onCommit,
  value,
}: {
  onSelect: (cur: string, type: number) => void;
  onCommit: () => void;
  value: string;
}) {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(
    Array.from(CompetitionPlatFormsIconsMap)
  );
  const handleConfirm = () => {
    postSubmitPlatformApi(searchText);
    onCommit();
  };

  const handleSearch = (value: string) => {
    setSearchText(value);
    const filtered = Array.from(CompetitionPlatFormsIconsMap).filter((option) =>
      option[0].toLowerCase().includes(value.toLowerCase())
    );
    setFilteredOptions(filtered);
  };
  return (
    <Select
      defaultValue={value}
      value={value}
      placeholder={t("Please select a comparison platform")}
      style={{
        width: "200px",
        height: "40px",
        verticalAlign: "middle",
      }}
      className={styles.customSelect}
      onSelect={(value) => onSelect(value, 1)}
      popupMatchSelectWidth={false}
      getPopupContainer={(triggerNode: any) => triggerNode.parentNode}
      dropdownRender={(menu) => (
        <div className={styles.dropdown} style={{ width: "400px" }}>
          <div className={styles.searchWrapper}>
            <Input
              placeholder={t("Please select a comparison platform")}
              value={searchText}
              onChange={(e) => handleSearch(e.target.value)}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              bordered={false}
              prefix={
                <SearchOutlined
                  className={`${styles.searchIcon} ${
                    isFocused ? styles.searchIconFocused : ""
                  }`}
                />
              }
              className={`${styles.searchInput} ${
                isFocused ? styles.searchInputFocused : ""
              }`}
            />
            <div className={styles.searchUnderline} />
            <div
              className={`${styles.focusLine} ${
                isFocused ? styles.focusLineActive : ""
              }`}
            />
          </div>
          <div className={styles.menuWrapper}>
            {filteredOptions.length > 0 ? (
              menu
            ) : (
              <div className={styles.emptyState}>
                <FileTextOutlined className={styles.emptyIcon} />
                <div className={styles.emptySubtext}>
                  {t(
                    "The platform may not have been updated, if you want to have requirements for the platform, you can proceed."
                  )}
                  <span onClick={handleConfirm}>{t("submit")}</span>
                  {t(
                    "We will collect the data of the platform as soon as possible."
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    >
      {filteredOptions.map((option: any) => (
        <Select.Option key={option[0]} value={option[0]}>
          <div className={styles.optionContent}>
            <div className={styles.optionLeft}>
              {/* <CheckCircleFilled className={styles.optionIcon} /> */}
              <img src={option[1]} alt="" />
              <span>{option[0]}</span>
            </div>
          </div>
        </Select.Option>
      ))}
    </Select>
  );
}
