import styles from "./index.module.less";
import { useUserProvider } from "@/context/UserProvider";
import { useTranslation } from "react-i18next";
const Index = () => {
  const { t } = useTranslation();
  const { onOpenLoginModal } = useUserProvider();
  return (
    <div className={styles.container}>
      <span onClick={() => onOpenLoginModal(true)}>{t("Join now")}</span>{" "}
      {t("to start monitoring and removing leaked content.")}
    </div>
  );
};

export default Index;
