import React, { useEffect } from "react";
import ArticleDetailUI from "./ContentUI";

const ArticleDetail = ({ data }: { data: any }) => {
  return (
    <ArticleDetailUI
      model={data["model"]}
      // gptsList={data['gptsList']}
      articleList={data["articleList"]}
      contentData={data["contentData"]}
      recommendTags={data["recommendTags"]}
    />
  );
};

export default ArticleDetail;
