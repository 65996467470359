import React from "react";
import style from "./index.module.less";
import Image from "next/image";
const Index: React.FC<any> = ({ showProInfo }) => {
  return (
    <div className={style.container}>
      <h2>{showProInfo.title}</h2>
      <ul className={style.mainList}>
        {showProInfo.mainList.map((item: any, index: number) => (
          <a href={item.a} key={index}>
            <li key={index}>
              <Image
                src={item.img}
                alt={item.title}
                height={80}
                width={80}
              ></Image>
              <h3 className={style.title}>{item.title}</h3>
              <div className={style.desc}>{item.desc}</div>
            </li>
          </a>
        ))}
      </ul>
    </div>
  );
};

export default Index;
