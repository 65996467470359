import useScoll from "@/context/UseScrollHeight";
import { Button } from "antd";
import { CustomSelect } from "./custom-select";
import styles from "./index.module.less";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import { useConfigProvider } from "@/context/ConfigProvider";
import OurModal from "@/components/Modal";
import { useTranslation } from "next-i18next";
import { useUserProvider } from "@/context/UserProvider";
import { largetalizeFirstLetter } from "@/lib/hooks/utils/utils";
const Index = () => {
  const { isLogin } = useUserProvider();
  const { isMobile } = useConfigProvider();
  const { t } = useTranslation();
  const [isOpenModal, setIsModalOpen] = useState(false);
  const router = useRouter();
  const curPlat = useRef<string>("");
  const subPlat = useRef<string>("");
  const [curPlatSta, setcurPlatSta] = useState<string>(null);
  const [subPlatSta, setsubPlatSta] = useState<string>(null);

  const handleSelect = (e: any, type: number) => {
    if (type === 1) {
      curPlat.current = e;
      setcurPlatSta(e);
    } else {
      subPlat.current = e;
      setsubPlatSta(e);
    }
  };
  const handleConfirm = () => {
    if (curPlatSta && subPlatSta) {
      router.push(
        `/compare/${largetalizeFirstLetter(
          curPlatSta
        )}-vs-${largetalizeFirstLetter(subPlatSta)}`
      );
    } else if (curPlat.current) {
      router.push(
        `/compare/${largetalizeFirstLetter(curPlatSta)}-alternatives`
      );
    } else if (subPlat.current) {
      if (curPlatSta && curPlatSta) {
        router.push(
          `/compare/${largetalizeFirstLetter(subPlatSta)}-alternatives`
        );
      }
    }
  };
  useEffect(() => {
    if (router.pathname.includes("-alternatives")) {
      setcurPlatSta(router.query.slug as string);
    } else if (router.pathname.includes("-vs-")) {
      const regex = /\/([a-zA-Z0-9-]+)-vs-([a-zA-Z0-9-]+)/;
      const matches = router.asPath.match(regex);
      if (matches) {
        setcurPlatSta(matches[1]);
        setsubPlatSta(matches[2]);
      }
    }
  }, []);
  const scrollY = useScoll();

  if (scrollY < 290 || isMobile) {
    return null;
  }

  return (
    <div
      className={styles.container}
      style={{ top: isLogin ? "60px" : "100px" }}
    >
      <div>
        <div>
          <CustomSelect
            onSelect={(e) => handleSelect(e, 1)}
            onCommit={() => setIsModalOpen(true)}
            value={curPlatSta}
          />
          <span>vs</span>
          <CustomSelect
            onSelect={(e) => handleSelect(e, 2)}
            onCommit={() => setIsModalOpen(true)}
            value={subPlatSta}
          />
          <Button type="primary" onClick={handleConfirm}>
            {t("Compare Now")}
          </Button>
        </div>
      </div>
      <div>
        <div className={styles.tag_head_time}>
          <ul className={styles.tagList}>
            <li>
              <a href="#Basic Information">{t("Overview")}</a>
            </li>
            <li>
              <a href="#Data Comparison">{t("Data Comparison")}</a>
            </li>
            <li>
              <a href="#Popular Topics">{t("Popular Topics")}</a>
            </li>
            <li>
              <a href="#Comparison with Other Platforms">
                {t("Comparison with Other Platforms")}
              </a>
            </li>
          </ul>
          <div>
            {t("Last Updated:")}
            <span>{dayjs().format("YYYY-MM-DD")}</span>
          </div>
        </div>
      </div>
      <OurModal
        isModalOpen={isOpenModal}
        handleCancel={() => setIsModalOpen(false)}
        width={350}
      >
        <div className={styles.modal}>
          <div>{t("Thank you for your submission")}</div>
          <div>
            {t(
              "We have received your platform submission, and we will include the platform as soon as possible after review. Thank you for your operation ~"
            )}
          </div>
          <div onClick={() => setIsModalOpen(false)}>{t("Confirm")}</div>
        </div>
      </OurModal>
    </div>
  );
};

export default Index;
