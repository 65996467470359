import OurModal from "@/components/Modal";
import React, { useState } from "react";
import style from "./index.module.less";
import { Button } from "antd";
interface Iprops {
  isModalOpen: boolean;
  handleClose: () => void;
}
import { getCustomerPortalApi } from "@/lib/service";
import { message } from "antd";

const Index: React.FC<Iprops> = ({ isModalOpen, handleClose }) => {
  const [loading, serLoading] = useState<boolean>(false);
  const handleManageStrip = () => {
    serLoading(true);
    getCustomerPortalApi().then((res) => {
      serLoading(false);
      if (res.code == 0) {
        window.open(res.data.url, "_self");
      } else {
        message.warning(res.message);
      }
    });
  };
  return (
    <OurModal isModalOpen={isModalOpen} handleCancel={handleClose} width={400}>
      <div className={style.container}>
        <div>
          <img src="/images/dashboard/error.webp" alt="erasa" />
        </div>
        <div>Payment Unsuccessful</div>
        <div>Please use a different payment method.</div>
        <div>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleManageStrip} loading={loading}>
            Try Again
          </Button>
        </div>
      </div>
    </OurModal>
  );
};

export default Index;
