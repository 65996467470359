import { Divider, Drawer } from "antd";
import { useToggle } from "react-use";
import classNames from "classnames";
import { useConfigProvider } from "@/context/ConfigProvider";
import styles from "./index.module.less";
import { useCallback, type ReactNode } from "react";
import { useUserProvider } from "@/context/UserProvider";
// import Link from 'next/link';
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import LeftFolder from "../leftFolder/index";

const NavHamburger = ({
  children,
  iNode,
}: {
  children?: ReactNode;
  iNode?: ReactNode;
}) => {
  const { isMobile } = useConfigProvider();
  const { isLogin, onOpenLoginModal, onChangedLoginView, onSignOut } =
    useUserProvider();
  const [open, setOpen] = useToggle(false);
  const router = useRouter();
  const { t } = useTranslation("common");

  const showLogin = (loginView: boolean) => {
    onOpenLoginModal(true);
    onChangedLoginView(loginView);
  };

  return (
    <div
      className={classNames(styles.navHamburgerWarp, {
        [styles.showClose]: open,
      })}
    >
      {iNode
        ? iNode
        : new Array(3)
            .fill(1)
            .map((_, index) => (
              <span
                onClick={() => setOpen(true)}
                key={`burger-${index}`}
                className={styles.hamburgerLine}
              />
            ))}
      <Drawer
        style={{ background: `var(--foreground-rgb)` }}
        title={null}
        closeIcon={null}
        width={isMobile ? 250 : 400}
        placement="left"
        onClose={(e) => {
          e.stopPropagation();
          setOpen(false);
        }}
        open={open}
        maskClosable={true}
        rootClassName={styles.leftMenuDrawerWarp}
      >
        <div onClick={() => setOpen(false)}>
          <LeftFolder></LeftFolder>
        </div>
      </Drawer>
    </div>
  );
};

export default NavHamburger;
