import OurModal from "@/components/Modal";
import React from "react";
import { Button } from "antd";
import style from "./index.module.less";

const Index: React.FC<any> = ({
  isModalOpen,
  handleCancel,
  handleOk,
  loading,
}) => {
  return (
    <OurModal isModalOpen={isModalOpen} handleCancel={handleCancel} width={350}>
      <div className={style.container}>
        <div>Confirm Subscription Change</div>
        <div>
          Click the button below to upgrade your subscription and unlock new
          features!
        </div>
        <Button onClick={handleOk} loading={loading}>
          Upgrade&Pay
        </Button>
      </div>
    </OurModal>
  );
};

export default Index;
