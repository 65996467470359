import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Check, X } from "lucide-react";
import styled, { keyframes } from "styled-components";
import { useTranslation } from "next-i18next";
import { CloseOutlined } from "@ant-design/icons";

interface Step {
  id: string;
  label: string;
  status: "pending" | "loading" | "completed";
}

// Keyframes for the loading animation
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const LoadingSpinner = styled.div`
  width: 20px;
  height: 20px;
  border: 2px solid #e5e7eb;
  border-top-color: #6b7280;
  border-radius: 50%;
  animation: ${spin} 0.8s linear infinite;
`;

const PendingCircle = styled.div`
  width: 20px;
  height: 20px;
  border: 2px solid #e5e7eb;
  border-radius: 50%;
`;

const ModalOverlay = styled.div`
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  z-index: 999;
`;

const ModalContent = styled.div`
  background-color: white;
  border-radius: 0.75rem;
  max-width: 23rem;
  width: 100%;
  padding: 1.5rem;
  position: relative;
`;

const CloseButton = styled.button`
  background: #fff;
  position: absolute;
  padding: 0;
  margin: 0;
  border: none;
  right: 1rem;
  top: 1rem;
  color: #6b7280;
  cursor: pointer;
  &:hover {
    color: #374151;
  }
`;

const Header = styled.div`
  margin-bottom: 1.5rem;
`;

const Title = styled.h2`
  font-size: 1.125rem;
  font-weight: 600;
  color: #111827;
`;

const Subtitle = styled.p`
  font-size: 0.875rem;
  color: #6b7280;
`;

const StepsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-bottom: 1.5rem;
  background: #f1f7fb;
  border-radius: 0.5rem;
  padding: 1rem;
`;

const StepItem = styled.div`
  display: flex;
  align-items: center;
  padding: 0.75rem;
  background-color: #fff;
  border-radius: 1.5rem;
`;

const StepIcon = styled.div`
  margin-right: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StepLabel = styled.span<{ completed: boolean }>`
  font-size: 0.875rem;
  color: ${(props) => (props.completed ? "#111827" : "#6b7280")};
`;

const CompletedCircle = styled(motion.div)`
  width: 20px;
  height: 20px;
  background-color: #22c55e;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ActionButton = styled(motion.button)`
  width: 100%;
  padding: 0.75rem;
  border: none;
  border-radius: 0.5rem;
  color: white;
  font-weight: 500;
  background: linear-gradient(to right, #3b82f6, #ec4899);
  &:hover {
    background: linear-gradient(to right, #2563eb, #db2777);
  }
`;

export default function AnimatedProtectionModal({
  onClick,
}: {
  onClick?: (isOk?: boolean) => void;
}) {
  const { t } = useTranslation();
  const [steps, setSteps] = useState<Step[]>([
    { id: "1", label: t("Leak Monitoring For Username"), status: "pending" },
    { id: "2", label: t("Auto Takedown"), status: "pending" },
    { id: "3", label: t("Whitelist Filtering"), status: "pending" },
    { id: "4", label: t("Multi-region Monitoring"), status: "pending" },
  ]);
  const [currentStep, setCurrentStep] = useState(0);
  const [buttonText, setButtonText] = useState(t("Processing..."));

  useEffect(() => {
    const animateSteps = async () => {
      for (let i = 0; i < steps.length; i++) {
        setCurrentStep(i);
        setSteps((prev) =>
          prev.map((step, index) =>
            index === i ? { ...step, status: "loading" } : step
          )
        );
        await new Promise((resolve) => setTimeout(resolve, 1500));
        setSteps((prev) =>
          prev.map((step, index) =>
            index === i ? { ...step, status: "completed" } : step
          )
        );
        await new Promise((resolve) => setTimeout(resolve, 500));
      }
      setButtonText(t("View Now"));
    };

    animateSteps();
  }, []);

  return (
    <ModalOverlay>
      <ModalContent>
        <CloseButton>
          <X
            size={20}
            onClick={() => {
              onClick && onClick(false);
            }}
          />
        </CloseButton>

        <Header>
          <Title>{t("Protection is being activated")}</Title>
          <Subtitle>
            {t("Initial protection setup in progress—stay tuned!")}
          </Subtitle>
        </Header>

        <StepsList>
          {steps.map((step, index) => (
            <StepItem key={step.id}>
              <StepIcon>
                <AnimatePresence mode="wait">
                  {step.status === "completed" ? (
                    <CompletedCircle
                      key="completed"
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                      exit={{ scale: 0 }}
                    >
                      <Check size={12} color="white" />
                    </CompletedCircle>
                  ) : step.status === "loading" ? (
                    <motion.div
                      key="loading"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                    >
                      <LoadingSpinner />
                    </motion.div>
                  ) : (
                    <motion.div
                      key="pending"
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                      exit={{ scale: 0 }}
                    >
                      <PendingCircle />
                    </motion.div>
                  )}
                </AnimatePresence>
              </StepIcon>
              <StepLabel completed={step.status === "completed"}>
                {step.label}
              </StepLabel>
            </StepItem>
          ))}
        </StepsList>

        <ActionButton
          onClick={() => {
            buttonText === t("View Now") && onClick && onClick(true);
          }}
          animate={{
            background:
              buttonText === t("View Now")
                ? "linear-gradient(to right, #3b82f6, #ec4899)"
                : "#02183A",
          }}
        >
          {buttonText}
        </ActionButton>
      </ModalContent>
    </ModalOverlay>
  );
}
