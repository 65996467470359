import OurModal from "@/components/Modal/index";
import React from "react";
import { Button } from "antd";
import style from "./index.module.less";
import { useTranslation } from "next-i18next";

interface Iprops {
  isModalOpen: boolean;
  handleCancel: () => void;
  url: string;
}

const Index: React.FC<Iprops> = ({ isModalOpen, handleCancel, url }) => {
  const { t } = useTranslation("common");
  return (
    <OurModal isModalOpen={isModalOpen} handleCancel={handleCancel} width={350}>
      <div className={style.container}>
        <div className={style.title}>{t("This is a third-party link.")}</div>
        <div className={style.url} onClick={() => window.open(url, "_blank")}>
          {url}
        </div>
        <div className={style.desc}>
          {t(
            "If you decide to continue, please be cautious of the information's authenticity and safeguard your privacy and assets."
          )}
        </div>
        <div className={style.btns}>
          <Button onClick={handleCancel}>{t("Cancel")}</Button>
          <Button
            onClick={() => {
              window.open(url, "_blank");
              handleCancel();
            }}
          >
            {t("Continue")}
          </Button>
        </div>
      </div>
    </OurModal>
  );
};
export default Index;
