import OurModal from "@/components/Modal/index";
import React, { useState } from "react";
import style from "./index.module.less";
import { Button } from "antd";
import AddAccountModal from "@/components/protectedAccount/AddAcountModal";
import { useTranslation } from "next-i18next";

const Index: React.FC<any> = ({ isModalOpen, handleClose }) => {
  const { t } = useTranslation("common");
  const [isAddAccountModal, setIsAddAccountModal] = useState(false);
  return (
    <div>
      <OurModal
        isModalOpen={isModalOpen}
        handleCancel={handleClose}
        width={400}
      >
        <div className={style.container}>
          <div className={style.title}>
            {t("Thank you for using our service！")}
          </div>
          <div>{t("Leaks are inevitable")}</div>
          <div>
            {t(
              "Protect your work with Erasa’s AI-powered tools，Join other creators in detecting and removing leaked content."
            )}
          </div>
          <div>
            <span onClick={() => setIsAddAccountModal(true)}>Add</span> your
            account and start your{" "}
            <span onClick={() => setIsAddAccountModal(true)}>
              free trial today!
            </span>
          </div>
          <div className={style.btn}>
            <Button onClick={() => setIsAddAccountModal(true)}>
              {t("Add Account")}
            </Button>
          </div>
        </div>
      </OurModal>
      <AddAccountModal
        isModalOpen={isAddAccountModal}
        handleCancel={() => setIsAddAccountModal(false)}
      ></AddAccountModal>
    </div>
  );
};

export default Index;
