import { getUserUseTimesApi } from "@/lib/service";
import { useUserProvider } from "./UserProvider";
import { useEffect, useState } from "react";
const Index = () => {
  const [userUse, setUserUse] = useState<any>({}); //用户订阅信息
  const { isLogin } = useUserProvider();
  const UpdateUserUs = () => {
    getUserUseTimesApi().then((res) => {
      setUserUse(res.data);
    });
  };
  useEffect(() => {
    if (isLogin) {
      getUserUseTimesApi().then((res) => {
        setUserUse(res.data);
      });
    }
  }, []);

  return {
    userUse,
    UpdateUserUs,
  };
};
export default Index;
