import styles from "./index.module.less";
import { Button } from "antd";
import Image from "next/image";
import { useTranslation } from "next-i18next";
import AddAccountModal from "@/components/protectedAccount/AddAcountModal";
import { useState } from "react";

const Index = () => {
  const { t } = useTranslation();
  const [isAddAccountModal, setIsAddAccountModal] = useState(false);
  return (
    <div className={styles.container}>
      <h2>{t("Start Removing Leaks with Erasa.ai")}</h2>
      <h3>
        {t(
          "leak will never stop ！Don't let leaks damage your hard work. With Erasa, you can effortlessly remove leaked content and regain control."
        )}
      </h3>
      <div>
        <Button onClick={() => setIsAddAccountModal(true)}>
          {t("Get Started")}
        </Button>
      </div>
      <Image
        src={"/images/erasa/competions-bottom/girl.webp"}
        alt="Erasa"
        height={328}
        width={470}
        loading="lazy"
      />
      <AddAccountModal
        isModalOpen={isAddAccountModal}
        handleCancel={() => setIsAddAccountModal(false)}
      />
    </div>
  );
};
export default Index;
