export const platFormsIconsMap = new Map([
  ["ONLYFANS", "/images/erasa/platforms/onlyfans.png"],
  ["TIKTOK", "/images/erasa/platforms/tiktok.png"],
  ["X", "/images/erasa/platforms/space.png"],
  ["INSTAGRAM", "/images/erasa/platforms/ins.png"],
  ["PATREON", "/images/erasa/platforms/patreon.png"],
  ["FANVUE", "/images/erasa/platforms/fanvue.png"],
  ["MANYVIDS", "/images/erasa/platforms/manyvids.png"],
  ["CHATURBATE", "/images/erasa/platforms/chaturbate.png"],
  ["FANSLY", "/images/erasa/platforms/fansly.png"],
  ["REDDIT", "/images/erasa/platforms/reddit.png"],
  ["PORNHUB", "/images/erasa/platforms/pornhub.png"],
  ["FANSONE", "/images/erasa/platforms/fansone.png"],
  ["www.pornhub.com", "/images/erasa/platforms/pornhub.png"],
  ["fansone.co", "/images/erasa/platforms/fansone.png"],
  ["", "/images/erasa/platforms/other.png"],
]);

export const CompetitionPlatFormsIconsMap = new Map([
  ["Onlyfans", "/images/erasa/platforms/onlyfans.png"],
  ["Fansly", "/images/erasa/platforms/fansly.png"],
  ["Patreon", "/images/erasa/platforms/patreon.png"],
  ["Manyvids", "/images/erasa/platforms/manyvids.png"],
  ["Fanvue", "/images/erasa/platforms/fanvue.png"],
  ["Fancentro", "/images/erasa/platforms/fancentro.png"],
  ["Ko-fi", "/images/erasa/platforms/ko-fi.png"],
  ["Mym", "/images/erasa/platforms/mym.png"],
  ["Fansone", "/images/erasa/platforms/fansone.png"],
  ["Umate", "/images/erasa/platforms/umate.png"],
  ["Fanspicy", "/images/erasa/platforms/fanspicy.png"],
  ["BrandArmy", "/images/erasa/platforms/BrandArmy.png"],
  ["Loyalfans", "/images/erasa/platforms/Loyalfans.png"],
  ["Pornhub", "/images/erasa/platforms/pornhub.png"],
  ["Fantia", "/images/erasa/platforms/Fantia.png"],
]);
